<template>
    <div>
        <navbar :items="navItems"/>
        <data-table :dataSource="recharges" :headers="headers"/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Navbar from '../../components/navBar.vue'
import DataTable from '../../components/dataTable/local.vue'
import { NUMERIC_TYPE, DATE_TYPE} from '../../components/dataTable/dataType'
export default {
components: {DataTable, Navbar},
    data(){
        return {
            navItems: [{name: 'Recharges client'}]
        }
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            recharges: 'host/recharges',
        }),
        headers(){
            return [
                {label: 'Volume (ml)', name: 'volume', type: NUMERIC_TYPE, sortable: true},
                {label: 'Prix (FCFA)', name: 'prix', type: NUMERIC_TYPE, sortable: true},
                {label: 'Date', name: 'date', type: DATE_TYPE, sortable: true},
            ]
        }
    }
}
</script>