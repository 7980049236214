<template>
    <div class="sub-header-container">
        <header class="header navbar navbar-expand-sm">
            <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom">
                <i class="las la-bars"></i>
            </a>
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="{name: 'home'}">
                                        <i class="icofont icofont-home"></i>
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page" v-for="(item, i) in items" :key="i">
                                    <router-link :to="{name: item.route}" v-if="item.route">{{item.name}}</router-link>
                                    <span v-else>{{item.name}}</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </header>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    props: {
        items: { type: Array, require: true }
    },
    data(){
        return {
            
        }
    }
}
</script>

<style lang="css">
.sub-header-container{
    margin-bottom: 20px;
}
</style>